<template>
  <div class="player-container" v-if="$store.getters.currentTracklist.length">
      <div class="row justify-content-center mx-0">
        <div class="col-md-12 px-0">
            <Player v-if="renderComponent" />
        </div>
      </div>
    </div>
</template>

<script>
import Player from './Player.vue';

export default {
  components: { Player },
  data: () => {
    return {
      renderComponent: true,
    }
  },
  computed: {
    currentTracklist () { return this.$store.getters.currentTracklist },
    currentTrack () { return this.$store.getters.currentTrack },
    currentTrackID () { return this.$store.getters.currentTrackId },
  },

  watch: {
    currentTrackID(newVal) {
      console.log('value changes detected, TrackID:', newVal);

      this.forceRerender()
    },

  },

  methods: {
     forceRerender() {
        console.log('Rerender player component..');

        this.renderComponent = false;

        this.$nextTick(() => {
          this.renderComponent = true;

        });
      }
  }
};
</script>

<style>

</style>