<template>
  <div class="player">

    <div v-if="$store.getters.showMinimizedPlayer" class="min text-white shadow-sm">
      <div class="row justify-content-center">
        <div class="col-md-9 bg-brand">
          <div @click="seek($event)" ref="progress" class="seeker" dir="ltr">
            <div class="progress" :style="{'width' : step + '%'}"></div>
            <!-- <div class="indicator" :style="{'width' : step + '%'}">
              <span class="icon float-start"></span>
            </div> -->
          </div>
          <div class="d-flex w-100">
            <a class="ripple ripple-surface text-white" href="" @click.prevent="">
              <i v-if="!pauseTrack" @click="play()" class="la la-play-circle la-3x py-2 px-3"></i>
              <i v-else @click="pause()" class="la la-pause-circle la-3x py-2 px-3"></i>
              </a>
            <div class="col d-flex flex-column justify-content-center">
              <div class="m-0 font-weight-bold"><strong>{{ $store.getters.currentTrack.name_ar }}</strong></div>
              <div class="text-sm text-white-50">
                <span v-for="author,index in $store.getters.currentTrack.authors" :key="author.id">
                  {{ author.name_ar }}
                <span v-if="index != Object.keys($store.getters.currentTrack.authors).length - 1" class="mx-2 font-weight-bold">· </span>
                </span>
              </div>
            </div>
            <div class="d-flex">
              <div class=""><i @click="$router.push({ name: 'Track', params: { id: $store.getters.currentTrack.id } })" class="ripple ripple-surface la la-angle-up la-lg p-4"></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="$route.name == 'Track'" class="full" dir="ltr">
      <div class="info px-4 py-2">
        <!-- <input @click="seek($event)" type="range" id="myRange" value="1" max="100" class="w-100"> -->
        <div @click="seek($event)" ref="progress" class="seeker">
          <div class="progress" :style="{'width' : step + '%'}"></div>
          <div class="indicator" :style="{'width' : step + '%'}">
            <span class="icon float-start"></span>
          </div>
        </div>
        <div class="time d-flex text-brand w-100">
          <div class="col text-end font-weight-bold">{{timer}}</div>
          <div class="col text-start">{{duration}}</div>
        </div>
      </div>

      <div class="controls px-2 mb-3">
        <div class="d-flex align-items-center text-brand">
          <div class="pe-3 text-center">
            <i @click="toggleRandom" :class="random ? 'text-danger':''" class="ripple ripple-surface la la-random la-lg py-2"></i>
          </div>
          <div class="col p-3 text-center">
            <i @click="prevButton ? previous() : ''" class="ripple ripple-surface la la-chevron-circle-left la-2x"></i>
          </div>
          <div class="col text-center">
            <div
              class="
                ripple ripple-surface
                bg-brand
                rounded-circle
                d-flex
                mx-auto
                text-light
                play-button
                align-items-center
                text-center
              "
            >
              <i v-if="!pauseTrack" @click="play()" class="la la-play-circle la-2x mx-auto p-3"></i>
              <i v-else @click="pause()" class="la la-pause-circle la-2x p-2 mx-auto p-3"></i>
            </div>
          </div>
          <div class="col p-3 text-center">
            <i @click="nextButton ? next() : ''" class="ripple ripple-surface la la-chevron-circle-right la-2x"></i>
          </div>
          <div class="ps-3 text-center">
            <i @click="toggleRepeat" :class="repeat ? 'text-danger':''" class="ripple ripple-surface la la-retweet la-lg py-2"></i>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import { ref, reactive, computed } from "vue";
import { useStore } from 'vuex'
import gql from 'graphql-tag';

import { Howl, Howler } from "howler";

export default {
  // props: ['trackslist', 'currentindex'],

  mounted() {
    // var sound = this.audios[this.index].howl;
    // var barWidth = (0.9 * 100) / 100;
    // this.sliderBtnVol = this.volBar.offsetWidth * barWidth + this.volBar.offsetWidth * 0.05 - 25;
  },

  setup(props) {
    const store = useStore();

    const trackslist = computed(() => store.getters.currentTracklist);
    let trackindex = computed(() => store.getters.currentTrackIndex);
    let optionRepeat = computed(() => store.getters.playerOptionRepeat);
    let optionShuffle = computed(() => store.getters.playerOptionShuffle);
    const index = ref(trackindex.value);

    const audios =
     ref(
      trackslist.value.map(t => {
        return { 
          ...t
          }
      })
    );
    console.log('audios', audios);
    console.log('current_index', index.value);

    let lastLoggedTime = ref(0);
    const loggingOffset = 30; // Log progress in seconds
    const step = ref(0);
    const nextButton = ref(true);
    const prevButton = ref(true);
    const random = ref(optionShuffle.value);
    const repeat = ref(optionRepeat.value);
    const duration = ref("00:00");
    const timer = ref("00:00");
    const pauseTrack = ref(false);
    const progress = ref(null);
    const volBar = ref(null);
    const sliderBtn = ref(0);
    // const sliderBtnVol = ref(null);
    const volumeProgress = ref(90);
    const mutePlayer = ref(false);
    const state = reactive({
      audioPlaying: [],
    });
    function formatTime(secs) {
      var minutes = Math.floor(secs / 60) || 0;
      var seconds = Math.floor(secs - minutes * 60) || 0;

      return (
        (minutes < 10 ? "0" : "") +
        minutes +
        ":" +
        (seconds < 10 ? "0" : "") +
        seconds
      );
    }
    function updateCache() {
      let audio = audios.value[index.value]
      store.commit('SET_TRACK', { 
          index: index.value,
          track: audio,
        })
    }
    function play() {
      console.log('index.value', index.value)
      // updateCache()
      var sound;
      var audio = audios.value[index.value];
      console.log('audio single', audio);
      if (audio.howl) {
        sound = audio.howl;
      } else {
        state.audioPlaying[index.value] = false;
        sound = audio.howl = new Howl({
          src: [audio.full_url],
          html5: true, // A live stream can only be played through HTML5 Audio.
          format: ["mp3", "aac"],
          onplay: function () {
            pauseTrack.value = true;
            nextButton.value = true;
            prevButton.value = true;
            duration.value = formatTime(sound.duration());
            requestAnimationFrame(stepFunction.bind(this));
            prepareLogData(sound.seek())
          },
          onpause: function () {
            pauseTrack.value = false;
            prepareLogData(sound.seek())
          },
          onend: function () {
            prepareLogData(sound.seek())
            next();
          },
          onseek: function () {
            window.requestAnimationFrame(stepFunction.bind(this));
          },
        });
      }

      sound.play();

      state.audioPlaying[index.value] = true;
    }
    function pause(indexo) {
      var audio = audios.value[index.value].howl;

      if (audio) {
        audio.pause();
        pauseTrack.value = false;
        state.audioPlaying[index.value] = false;
      }
    }

    function stop() {
      var audio = audios.value[index.value].howl;

      if (audio) {
        audio.stop();
        pauseTrack.value = false;
        state.audioPlaying[index.value] = false;
      }
    }

    function stepFunction() {
      var sound = audios.value[index.value].howl;
      var seek = sound.seek();
      timer.value = formatTime(Math.round(seek));
      step.value = (seek * 100) / sound.duration();

      if (progress.value) {
        sliderBtn.value =
          progress.value.offsetWidth * (step.value / 100) +
          progress.value.offsetWidth * 0.05 -
          25;
      }

      if (sound.playing()) {
        store.commit('SET_IS_PLAYING', true)
        window.requestAnimationFrame(stepFunction.bind(this));
        onPlayerTimeUpdated()
      } else {
        store.commit('SET_IS_PLAYING', false)
      }
    }

    function seek(event) {
      var per = event.offsetX / progress.value.clientWidth;

      var sound = audios.value[index.value].howl;

      if (sound) {
        if (sound.playing()) {
          sound.pause();
          sound.seek(sound.duration() * per);
          var barWidth = (per * 100) / 100;
          sliderBtn.value =
            progress.value.offsetWidth * barWidth +
            progress.value.offsetWidth * 0.05 -
            25;
          sound.play();
        } else {
          sound.seek(sound.duration() * per);
          var barWidth = (per * 100) / 100;
          sliderBtn.value =
            progress.value.offsetWidth * barWidth +
            progress.value.offsetWidth * 0.05 -
            25;
        }
      }
    }

    function next() {
      nextButton.value = false;
      var audio = audios.value[index.value].howl;

      prepareLogData(audio.seek())

      state.audioPlaying[index.value] = false;

      mutePlayer.value ? (mutePlayer.value = false) : "";
      audio && audio.mute(true) ? audio.mute(false) : "";

      if (audio && audios.value.length - 1 == index.value) {
        audio.stop();

        repeat.value
          ? (index.value = index.value)
          : random.value
          ? (index.value = Math.floor(Math.random() * audios.value.length))
          : (index.value = 0);
      } else {
        if (audio) {
          audio.stop();
        }

        repeat.value
          ? (index.value = index.value)
          : random.value
          ? (index.value = Math.floor(Math.random() * audios.value.length))
          : index.value++;
      }

      updateCache()
      play();
    }

    function previous() {
      var audio = audios.value[index.value].howl;

      prepareLogData(audio.seek())

      prevButton.value = false;
      state.audioPlaying[index.value] = false;

      mutePlayer.value ? (mutePlayer.value = false) : "";
      audio && audio.mute(true) ? audio.mute(false) : "";

      if (!audio) {
        index.value = audios.value.length - 1;
      } else if (audio && index.value == 0) {
        audio.stop();

        repeat.value
          ? (index.value = index.value)
          : random.value
          ? (index.value = Math.floor(Math.random() * audios.value.length))
          : (index.value = audios.value.length - 1);
      } else if (audio) {
        audio.stop();

        repeat.value
          ? (index.value = index.value)
          : random.value
          ? (index.value = Math.floor(Math.random() * audios.value.length))
          : index.value--;
      }

      updateCache()
      play();
    }
    function selectSound(indexSelected) {
      var audio = audios.value[index.value].howl;

      if (audio) {
        audio.stop();
        state.audioPlaying[index.value] = false;
      }

      index.value = indexSelected;

      play();
    }

    function toggleRepeat () {
      repeat.value = !repeat.value
      store.commit('UPDATE_PLAYER_OPTION_REPEAT', repeat.value)
    }

    function toggleRandom () {
      random.value = !random.value
      store.commit('UPDATE_PLAYER_OPTION_SHUFFLE', random.value)
    }

    function volume(event) {
      var per = event.layerX / parseFloat(volBar.value.scrollWidth);
      var barWidth = (per * 100) / 100;
      volumeProgress.value = barWidth * 100;
    //   sliderBtnVol.value =
    //     volBar.value.offsetWidth * barWidth +
    //     volBar.value.offsetWidth * 0.05 -
    //     25;
      Howler.volume(per);
    }

    function mute() {
      var audio = audios.value[index.value].howl;

      if (audio) {
        mutePlayer.value = !mutePlayer.value;

        mutePlayer.value ? audio.mute(true) : audio.mute(false);
      }
    }

    function onPlayerTimeUpdated () {
      var sound = audios.value[index.value].howl;
      if (sound) {
        var currentTime = sound.seek()
        if (Number.parseInt(currentTime) % loggingOffset === 0 && Number.parseInt(currentTime) !== lastLoggedTime.value) {
          prepareLogData(currentTime)
        }
      }
    }

    function prepareLogData(currentTime) {
      var secondsToLog = Number.parseInt(currentTime - lastLoggedTime.value)
      secondsToLog = secondsToLog >= 0 ? secondsToLog : 0
      var progress = Number.parseInt(currentTime * 1000)
      // console.log('log to the server:', lastLoggedTime.value, currentTime, secondsToLog, progress)
      lastLoggedTime.value = Number.parseInt(currentTime)
      if(secondsToLog == 0 && progress > 0) {
        return
      }
      logListeningToServer(secondsToLog, progress)
    }


    // TODO: use apollo composition api instead
    let logListeningToServerFlag = ref(0)
    let secondsToLogToServer = ref(0)
    let progressToLogToServer = ref(0)
    function logListeningToServer(secondsToLog, progressMilliseconds) {
      secondsToLogToServer.value = secondsToLog
      progressToLogToServer.value = progressMilliseconds
      logListeningToServerFlag.value += 1
    }


    return {
      logListeningToServerFlag,
      secondsToLogToServer,
      progressToLogToServer,
      play,
      pause,
      stop,
      duration,
      formatTime,
      audios,
      pauseTrack,
      next,
      previous,
      index,
      timer,
      step,
      stepFunction,
      seek,
      selectSound,
      state,
      random,
      repeat,
      progress,
      volume,
      volBar,
      volumeProgress,
      sliderBtn,
      toggleRepeat,
      toggleRandom,
      mute,
      mutePlayer,
    //   sliderBtnVol,
      nextButton,
      prevButton,
    };
  },

  mounted() {
    this.play()
  },

  unmounted() {
    console.log('Unmounting player component...')
    this.stop()
  },

  watch: {
    logListeningToServerFlag (val) {
      if (val > 0) {
        this.logListeningToServerAction()
      }
    }
  },

  methods: {
    logListeningToServerAction () {
      // console.log('loged to the server:', this.secondsToLogToServer, this.progressToLogToServer)
      this.$apollo.mutate({
          mutation: gql`
              mutation ($input: LogTrackListeningInput!) {
                  logTrackListening(input: $input) {
                      id
                      seconds
                      progress
                      user_id
                      track_id
                  }
              }
          `,
          // Parameters
          variables: {
            input: {
              track_id: Number.parseInt(this.$store.getters.currentTrack?.id),
              seconds: this.secondsToLogToServer,
              progress: this.progressToLogToServer,
              source: 'imanyat.com'
            },
          },
      })
      .then((res) => {
          // Result
          console.log(res.data.logTrackListening)
      }).catch((error) => {
          // Error
          console.error(error)
      });
    }
  },
};
</script>

<style>

#progressButtonTimer,
#progressButtonVolume {
  margin-top: -9px;
  right: -8px;
}

@media screen and (max-width: 768px) {
  #progressButtonTimer,
  #progressButtonVolume {
    margin-top: -8px;
    right: -7px;
  }
}
</style>
