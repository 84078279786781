<template>
  <router-link :to="{ name: 'Program', params: { id: program.id } }" class="item vertical-card pb-3 mb-3 me-3 shadow-sm card-hover">
    <div class="image">
      <img :src="program.thumbnail_url" alt="" class="author-image bg-brand" loading="lazy" />
    </div>
    <div class="labels col px-2">
      <div class="font-weight-bold mt-2 card-title">{{ program.name_ar }}</div>
      <div class="text-sm card-secondary-lable">
        <div class="d-inline-block" v-for="author,index in program.authors" :key="author.id">
          {{ author.name_ar }}
          <span v-if="index != Object.keys(program.authors).length - 1" class="mx-2 font-weight-bold">· </span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ['program'],
};
</script>

<style></style>
