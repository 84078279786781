import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import VOtpInput from "vue3-otp-input";
import VieOtpInput from "@bachdgvn/vue-otp-input";
import * as apolloProvider from './apollo.provider'
require("./assets/js/mdb.min");


import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const app = createApp(App)

const toastOptions = {
    rtl: true,
    position: "top-center",
}

app.use(store)
app.use(router)
app.use(apolloProvider.provider)
app.use(Toast, toastOptions)
// app.component('v-otp-input', VOtpInput)
app.component("vie-otp-input", VieOtpInput);
app.mount("#app");