<template>
  <div @click="openTrack" class="cursor-pointer text-brand" :class="{ 'card-secondary-lable': playedTrackID != track.id }">
    <div
      class="
        item
        played-tracklist-card
        d-flex
        border-bottom card-devider-border
        pb-3
        mb-3
      "
    >
      <div class="image me-3">
        <div>
          <img :src="track.thumbnail_url" alt="" class="author-image shadow-sm ms-1" />
        </div>
      </div>
      <div class="labels col">
        <div class="font-weight-bold">{{ track.name_ar }}</div>

        <div class="mb-1">
          <div
            class="text-sm d-inline-block"
            v-for="(author, index) in track.authors"
            :key="author.id"
          >
            {{ author.name_ar }}
            <span
              v-if="index != Object.keys(track.authors).length - 1"
              class="mx-2 font-weight-bold"
              >·
            </span>
          </div>
        </div>
      </div>
      <div
        class="
          text-center text-sm
          d-flex
          flex-column
          justify-content-center
        "
      >
        <!-- <div class="font-weight-bold">المدة</div>
        <div>11:49</div> -->
      </div>
      <div class="
          d-flex
          flex-column
          justify-content-center
          ">
        <div class="ps-3 overflow-hidden">
          <i v-if="playedTrackID == track.id" class="la la-compact-disc la-spin la-2x p-2"></i>
          <i v-else class="la la-play-circle la-2x p-2"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["track", "tracklist"],

  computed: {
    playedTrackID() {
        return this.$store.getters.currentTrackId
    },
    selectedTrackIndex() {
      let index = 0;
      this.tracklist.forEach((t, i) => {
        if (t.id == this.track.id) {
          index = i;
        }
      });
      return index;
    },
  },

  methods: {
    openTrack() {
      this.$store.commit("SET_TRACKLIST", {
        tracklist: this.tracklist,
        index: this.selectedTrackIndex,
        track: this.track,
      });
      // this.$router.push({ name: 'Track', params: { id: this.track.id } })
    },
  },
};
</script>

<style></style>
