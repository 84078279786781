import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/pages/Home.vue";
import auth from '../middlewares/auth';

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/pages/404.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import("../views/pages/About.vue"),
  // },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/pages/subscription/Login.vue"),
  },
  {
    path: "/login/subscribe",
    name: "LoginInfo",
    component: () => import("../views/pages/subscription/LoginInfo.vue"),
  },
  {
    path: "/register/subscribe",
    name: "RegisterViaOTP",
    component: () => import("../views/pages/subscription/RegisterViaOTP.vue"),
  },
  {
    path: "/categories",
    name: "Categories",
    component: () => import("../views/pages/Categories.vue"),
  },
  {
    path: "/categories/:id",
    name: "Category",
    component: () => import("../views/pages/Category.vue"),
  },
  {
    path: "/programs/:id",
    name: "Program",
    component: () => import("../views/pages/Program.vue"),
  },
  {
    path: "/authors/",
    name: "Authors",
    component: () => import("../views/pages/Authors.vue"),
  },
  {
    path: "/authors/:id",
    name: "Author",
    component: () => import("../views/pages/Author.vue"),
  },
  {
    path: "/playlist/:id",
    name: "Playlist",
    component: () => import("../views/pages/Playlist.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: "/tracks/:id",
    name: "Track",
    component: () => import("../views/pages/Track.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: "/search/",
    name: "Search",
    component: () => import("../views/pages/Search.vue"),
  },
  {
    path: "/favorite/",
    name: "Favorite",
    component: () => import("../views/pages/Favorite.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: "/profile/",
    name: "Profile",
    component: () => import("../views/pages/Profile.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: "/notifications/",
    name: "Notifications",
    component: () => import("../views/pages/Notifications.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;
  
    return (...parameters) => {
      // Run the default Vue Router `next()` callback first.
      context.next(...parameters);
      // Then run the subsequent Middleware with a new
      // `nextMiddleware()` callback.
      const nextMiddleware = nextFactory(context, middleware, index + 1);
      subsequentMiddleware({ ...context, next: nextMiddleware });
    };
  }
  
  router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
      const middleware = Array.isArray(to.meta.middleware)
        ? to.meta.middleware
        : [to.meta.middleware];
  
      const context = {
        from,
        next,
        router,
        to,
      };
      const nextMiddleware = nextFactory(context, middleware, 1);
  
      return middleware[0]({ ...context, next: nextMiddleware });
    }
  
    return next();
  });

export default router;
