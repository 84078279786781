<template>
  <router-link :to="{ name: 'Program', params: { id: program.id } }">
    <div class="item horizontal-card d-flex shadow-sm p-2 mb-2 card-hover">
      <div class="image me-3">
        <img :src="program.thumbnail_url" alt="" class="author-image bg-brand" loading="lazy" />
      </div>
      <div class="labels col flex-column d-flex justify-content-center">
        <div class="font-weight-bold h4 card-title">{{ program.name_ar }}</div>
        <div class="text-sm mb-1 card-secondary-lable"><strong>{{ program.tracksCount }}</strong> ملف صوتي</div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ['program'],
};
</script>

<style></style>
