import { InMemoryCache, ApolloClient } from "@apollo/client";
import { createApolloProvider } from "@vue/apollo-option";
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { AUTH_TOKEN_KEY } from './constants'

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP || "http://127.0.0.1:8000/graphql",
})

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null    }
  }
})

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
});

export const provider = createApolloProvider({
  defaultClient: apolloClient,
});
