<template>
    <div class="col-md-12 mb-2" v-for="i in 4" :key="i">
        <div class="shadow-sm">
            <ContentLoader width="400" height="100" >
            <rect x="311" y="10" rx="7" ry="7" width="80" height="80" />
            <rect x="155" y="25" rx="3" ry="3" width="140" height="20" />
            <rect x="165" y="65" rx="3" ry="3" width="130" height="10" />
            </ContentLoader>
        </div>
    </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
    components: {
        ContentLoader,
    }
}
</script>

<style>

</style>