<template>
  <div class="bottom-navigation">
    <PlayerContainer />
    <div class="d-block d-md-none">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="d-flex">
            <router-link :to="{ name: 'Home' }" class="col item ripple ripple-surface">
              <div class="text-center icon">
                <i class="la la-2x la-home"></i>
              </div>
              <div class="text-center label mb-1">الرئيسية</div>
            </router-link>
            <router-link :to="{ name: 'Categories' }" class="col item ripple ripple-surface">
              <div class="text-center icon">
                <i class="la la-2x la-table"></i>
              </div>
              <div class="text-center label mb-1">اكتشف</div>
            </router-link>
            <router-link :to="{ name: 'Authors' }" class="col item ripple ripple-surface">
              <div class="text-center icon">
                <i class="la la-2x la-microphone"></i>
              </div>
              <div class="text-center label mb-1">المتحدثون</div>
            </router-link>
            <router-link :to="{ name: 'Favorite' }" class="col item ripple ripple-surface">
              <div class="text-center icon">
                <i class="la la-2x la-heart-o"></i>
              </div>
              <div class="text-center label mb-1">المفضلة</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerContainer from '../../components/Player/PlayerContainer.vue'
export default {
  components: { PlayerContainer },}
</script>

<style></style>
