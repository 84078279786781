<template>
  <router-link :to="{ name: 'Category', params: { id: category.id } }">
    <div
      class="category-item bg-brand py-3 mb-3 rounded-md text-light text-center"
    >
      <div class="icon"><img :src="category.icon_url" alt=""></div>
      <div class="label font-weight-bold text-sm">{{ category.name_ar }}</div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ['category'],
};
</script>

<style></style>
