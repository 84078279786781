<template>
    <div class="item vertical-card pb-3 mb-3 me-3 shadow-sm" v-for="i in 7" :key="i">
        <ContentLoader width="170" height="150">
        <rect x="0" y="0" rx="3" ry="3" width="170" height="100" />
        <rect x="20" y="115" rx="3" ry="3" width="140" height="15" />
        <rect x="30" y="140" rx="3" ry="3" width="130" height="10" />
        </ContentLoader>
    </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
    components: {
        ContentLoader,
    }
}
</script>

<style>

</style>