<template>
  <div class="modal fade show d-block" id="playedTrackListModal" v-if="$store.state.showPlayedTracklistModal" tabindex="-1" role="dialog" aria-labelledby="playedTrackListModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content shadow-0">
          <div class="modal-body">
              <div class="tracks overflow-scroll">
                <div><h5 class="mb-4 d-flex align-items-center">
                  <a @click.prevent="$store.commit('UPDATE_PLAYED_TRACKLIST_MODAL_SHOW', false)" class="ripple ripple-surface cursor-pointer card-secondary-lable me-3"><i class="la la-arrow-right la-lg py-1"></i></a>
                  قائمة التشغيل الحالية
                </h5></div>

                <div class="" v-for="track in $store.getters.currentTracklist" :key="track.id">
                    <PlayedTracklstCard :track="track" :tracklist="$store.getters.currentTracklist" />
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import PlayedTracklstCard from './PlayedTracklstCard.vue'
export default {
  components: { PlayedTracklstCard },

}
</script>

<style>

</style>