<template>
  <div class="card-hover">
    <div class="item horizontal-card d-flex p-2 mb-2 shadow-sm rounded">
      <div class="image me-3 cursor-pointer" @click="openTrack">
        <div>
          <img :src="track.thumbnail_url" alt="" class="author-image shadow-sm me-2" loading="lazy" />
        </div>
      </div>
      <div class="labels card-secondary-lable cursor-pointer d-flex flex-column justify-content-center" @click="openTrack">
        <div class="font-weight-bold card-secondary-lable">{{ track.name_ar }}</div>

        <div class="mb-1">
          <div class="text-sm d-inline-block" v-for="author,index in track.authors" :key="author.id">
            {{ author.name_ar }}
            <span v-if="index != Object.keys(track.authors).length - 1" class="mx-2 font-weight-bold">· </span>
          </div>
        </div>
        <div class="d-block d-md-none">
          <span
            v-for="category in track.categories"
            :key="category.id"
            class="
              bg-brand
              rounded-md
              d-inline-block
              py-1
              px-3
              me-1
              mb-1
              text-xs text-light
              font-weight-bold
            "
            >{{ category.name_ar }}</span
          >
        </div>
      </div>
      <div class="col d-none text-center d-md-flex flex-column justify-content-center">
        <div>
          <span
            v-for="category in track.categories"
            :key="category.id"
            class="
              bg-brand
              rounded-md
              d-inline-block
              py-1
              px-3
              me-1
              mb-1
              text-xs text-light
              font-weight-bold
            "
            >{{ category.name_ar }}</span>
        </div>
        </div>
      <div class="col"></div>
      <div class="text-center text-sm card-secondary-lable d-flex flex-column justify-content-center" v-if="track.duration_display">
        <div class="font-weight-bold">المدة</div>
        <div>{{ track.duration_display}}</div>
      </div>
      <div class="
            d-flex
            flex-column
            justify-content-center
            px-md-2
            ">
          <div class="ps-3 overflow-hidden">
            <span v-if="$store.getters.user">
              <i v-if="playedTrackID == track.id" class="la la-compact-disc la-3x p-2 text-primary" :class="{ 'la-spin': playerIsPlaying }"></i>
              <i v-else class="la la-play-circle la-2x p-2 cursor-pointer" @click="openTrack(false)"></i>
            </span>
            <router-link :to="{ name: 'LoginInfo' }" v-else class="text-black-50">
              <i class="la la-play-circle la-2x p-2 cursor-pointer"></i>
            </router-link>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['track', 'tracklist'],

  computed: {
    playerIsPlaying() {
        return this.$store.getters.playerIsPlaying
    },
    playedTrackID() {
        return this.$store.getters.currentTrackId
    },
    selectedTrackIndex () {
      let index = 0;
      this.tracklist.forEach((t, i) => {
        if (t.id == this.track.id) {
          index = i;
        }
      });
      return index;
    }
  },

  methods: {
    openTrack(showPlayer = true) {
      if(this.$store.getters.isLoggedIn) {
        this.$store.commit('SET_TRACKLIST', { 
          tracklist: this.tracklist, 
          index: this.selectedTrackIndex,
          track: this.track,
          })
      }
      if(showPlayer) {
        this.$router.push({ name: 'Track', params: { id: this.track.id } })
      }
      return;
    }
  }
};
</script>

<style></style>
