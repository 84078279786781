<template>
    <div class="item vertical-card pb-3 mb-3 me-3 shadow-sm rounded-md" v-for="i in 9" :key="i">
        <ContentLoader width="95" height="80">
        <rect x="25" y="15" rx="3" ry="3" width="45" height="45" />
        <rect x="15" y="70" rx="3" ry="3" width="65" height="15" />
        </ContentLoader>
    </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
    components: {
        ContentLoader,
    }
}
</script>

<style>

</style>