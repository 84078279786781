<template>
  <div class="" dir="rtl">
    <div class="vh-100 w-100 mx-0 show">
      <div
        style=""
        class="p-0 h-100 text-white w-sidebar sidebar collapse show"
      >
        <Sidebar />
      </div>
      <div class="main-container col px-0">
        <Navbar />
        <div class="row justify-content-center mx-0">
          <div class="col-md-9 px-0">
            <main class="main px-0 px-md-4 pt-3 pb-5">
              <slot name="mainView"></slot>
            </main>
          </div>
        </div>
        <BottomNavigation />
      </div>
    </div>

    <!-- Modals -->
    <!-- <PlayedTrackInfo /> -->
    <PlayedTracklist />
  </div>
</template>

<script>
// import PlayedTrackInfo from '../../components/Player/PlayedTrackInfo.vue';
import PlayedTracklist from '../../components/Player/PlayedTracklist.vue';
import BottomNavigation from "./BottomNavigation.vue";
import Navbar from "./Navbar.vue";
import Sidebar from "./Sidebar.vue";

export default {
  components: {
    BottomNavigation,
    Navbar,
    Sidebar,
    // PlayedTrackInfo,
    PlayedTracklist,
  },
};
</script>

<style lang="scss">
@import "../../assets/css/mdb.rtl.min";
@import "../../assets/css/custom";
</style>
